import React, {ReactNode, useEffect, useState} from 'react';
import {Table} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get, orderBy} from 'lodash';

import SelectTableCell from './SelectTableCell';
import ResultsLimiter, {LimitOption} from './ResultsLimiter';
import Paginator from './Paginator';
import {SelectTableCellData} from '../types';

type BaseHeader = {
  className?: string
  colSpan?: number
}

type StandardHeader = BaseHeader & {
  title?: string
}

type SortableHeader = BaseHeader & {
  title?: string
  sortKey: string
}

type SelectAllHeader = BaseHeader & {
  dataIdKey: string
  htmlIdPrefix?: string // Use to avoid HTML id conflicts across tables, specify unique prefix for table and column
  selectKey: string
  onChange: (data: SelectTableCellData) => void
}

type Header = StandardHeader | SortableHeader | SelectAllHeader;

type SortOrder = {
  sortKey: string
  direction: 'asc' | 'desc'
}

type ResultsLimiterConfig = {
  message: string
  recordName?: string
  pluralRecordName?: string
  limitOptions?: LimitOption[]
  initialResultLimit?: number
}

type PaginatorConfig = {
  perPage: number
  recordName?: string
  currentPage?: number
  setCurrentPage?: (nextPage: number) => void
  allowShowAll?: boolean
  showingAll?: boolean
  setShowingAll?: (showingAll: boolean) => void
}

type Props = {
  className?: string
  headers: Header[] | Header[][]
  headerRowClassName?: string
  chainSort?: boolean
  initialSort?: SortOrder | SortOrder[]
  items: object[]
  renderRow: (item: any) => ReactNode
  noResultsMessage?: string
  renderFooter?: () => ReactNode
  resultsLimiterConfig?: ResultsLimiterConfig
  resultLimit?: number | null
  paginatorConfig?: PaginatorConfig
  bordered?: boolean // From Reactstrap Table
  borderless?: boolean // From Reactstrap Table
  dark?: boolean // From Reactstrap Table
  hover?: boolean // From Reactstrap Table
  responsive?: boolean // From Reactstrap Table
  striped?: boolean // From Reactstrap Table
  size?: 'sm' // From Reactstrap Table
}

const CustomTable = ({
                       className,
                       headers,
                       headerRowClassName,
                       chainSort,
                       initialSort,
                       items,
                       renderRow,
                       noResultsMessage,
                       renderFooter,
                       resultsLimiterConfig,
                       resultLimit,
                       paginatorConfig,
                       bordered = true,
                       borderless = false,
                       dark = false,
                       hover = true,
                       responsive = true,
                       striped = true,
                       size
                     }: Props) => {
  const hasMultipleHeaderRows = headers[0] instanceof Array;
  const sortable = hasMultipleHeaderRows ? headers.some(headerRow => (headerRow as Header[]).some(header => (header as SortableHeader).sortKey)) :
    headers.some(header => (header as SortableHeader).sortKey);
  const [currentSort, setCurrentSort] = useState<SortOrder[]>(initialSort ? initialSort instanceof Array ? initialSort : [initialSort] : []);
  const [resultLimiterSelection, setResultLimiterSelection] = useState<number | null>(
    resultsLimiterConfig && resultsLimiterConfig.initialResultLimit ? resultsLimiterConfig.initialResultLimit : null
  );
  const [showingAll, setShowingAll] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [itemsToDisplay, setItemsToDisplay] = useState<any[]>([]);
  const showAll = paginatorConfig && paginatorConfig.showingAll ? paginatorConfig.showingAll : showingAll;

  // Null and undefined don't sort as "blanks" by default. This works around that and does not appear to affect
  // the desired behavior of numerical, boolean, or date sorts.
  const sortIterate = (item: object, property: string) => {
    // Null will be returned for any undefined values
    const value = get(item, property, null);
    // Translate null values to empty strings to get the expected sort
    return value === null ? '' : value;
  };

  const renderSortIcon = (sortKey: string | undefined) => {
    const existingSort = currentSort.find(sortOrder => sortOrder.sortKey === sortKey);
    if (existingSort) {
      switch (existingSort.direction) {
        case 'asc':
          return (
            <FontAwesomeIcon icon="arrow-up"
                             className="mr-1"/>

          );
        case 'desc':
          return (
            <FontAwesomeIcon icon="arrow-down"
                             className="mr-1"/>
          );
        default:
          return null;
      }
    }
    return null;
  };

  const handleSort = (sortKey: string) => {
    if (!chainSort) {
      if (currentSort.length === 0) {
        setCurrentSort([{sortKey, direction: 'asc'}]);
      } else if (currentSort[0].sortKey === sortKey && currentSort[0].direction === 'asc') {
        setCurrentSort([{sortKey, direction: 'desc'}]);
      } else if (currentSort[0].sortKey === sortKey && currentSort[0].direction === 'desc') {
        setCurrentSort([]);
      } else {
        setCurrentSort([{sortKey, direction: 'asc'}]);
      }
    } else {
      const existingSortIndex = currentSort.findIndex(sortOrder => sortOrder.sortKey === sortKey);
      let newSort: SortOrder[] = [...currentSort];
      if (existingSortIndex === -1) {
        newSort.push({sortKey: sortKey, direction: 'asc'});
      } else if (currentSort[existingSortIndex].direction === 'asc') {
        newSort[existingSortIndex].direction = 'desc';
      } else if (currentSort[existingSortIndex].direction === 'desc') {
        newSort = currentSort.filter(sortOrder => sortOrder.sortKey !== sortKey);
      } else {
        newSort[existingSortIndex].direction = 'asc';
      }
      setCurrentSort(newSort);
    }
  };

  const renderHeaderRow = (headers: Header[], headerRowIndex: number = 0) => {
    return (
      <tr className={headerRowClassName} key={headerRowIndex}>
        {headers.map((header, index) => {
          if ((header as SelectAllHeader).selectKey) {
            if (itemsToDisplay.length === 0) {
              return (
                <th key={index}
                    role="columnheader"
                    scope="col"/>
              );
            } else {
              const selectAllHeader = header as SelectAllHeader;
              const allSelected = itemsToDisplay.every(item => get(item, selectAllHeader.selectKey));
              const ids = itemsToDisplay.map(item => item[selectAllHeader.dataIdKey]);
              return (
                <SelectTableCell key={`${index}-select-all`}
                                 itemId={`${selectAllHeader.htmlIdPrefix}-${index}-select-all`}
                                 itemIds={ids}
                                 isHeader={true}
                                 selected={allSelected}
                                 ariaLabel="Select All"
                                 onChange={selectAllHeader.onChange}/>
              );
            }
          } else if ((header as SortableHeader).sortKey) {
            const sortableHeader = header as SortableHeader;
            return (
              <th key={index}
                  role="columnheader"
                  scope="col"
                  tabIndex={0}
                  colSpan={header.colSpan ? header.colSpan : undefined}
                  className={`${header.className} cursor-pointer`}
                  onKeyDown={(e) => e.key == 'Enter' ? handleSort(sortableHeader.sortKey) : null}
                  onClick={() => handleSort(sortableHeader.sortKey)}>
                {renderSortIcon(sortableHeader.sortKey)}{sortableHeader.title}
              </th>
            );
          } else {
            const standardHeader = header as StandardHeader;
            return (
              <th key={index}
                  role="columnheader"
                  scope="col"
                  colSpan={header.colSpan ? header.colSpan : undefined}
                  className={header.className}>
                {standardHeader.title}
              </th>
            );
          }
        })}
      </tr>
    );
  };

  const renderHeaders = () => {
    return (
      <thead>
        {hasMultipleHeaderRows && (headers as Header[][]).map((headers, index) => renderHeaderRow(headers, index))}
        {!hasMultipleHeaderRows && renderHeaderRow(headers as Header[])}
      </thead>
    );
  };

  const renderBody = () => {
    const noResultsColSpan = hasMultipleHeaderRows ?
      Math.max(...headers.map(headerRow => (headerRow as Header[]).length)) : headers.length;
    return (
      <tbody>
        {itemsToDisplay.map(renderRow)}
        {itemsToDisplay.length === 0 && noResultsMessage &&
          <tr className="TableNoResults">
            <td colSpan={noResultsColSpan}>{noResultsMessage}</td>
          </tr>
        }
      </tbody>
    );
  };

  const renderTableFooter = () => renderFooter && <tfoot>{renderFooter()}</tfoot>;

  const defaultResultLimiterOnClick = (value: number | null) => setResultLimiterSelection(value);

  const renderResultsLimiter = () => {
    return resultsLimiterConfig && <ResultsLimiter message={resultsLimiterConfig.message}
                                                   recordName={resultsLimiterConfig.recordName}
                                                   pluralRecordName={resultsLimiterConfig.pluralRecordName}
                                                   limitOptions={resultsLimiterConfig.limitOptions}
                                                   resultLimit={resultLimiterSelection}
                                                   totalRecords={items.length}
                                                   handleClick={defaultResultLimiterOnClick}/>;
  };

  const renderPaginator = () => {
    if (!paginatorConfig) {
      return null;
    } else {
      const currPage = paginatorConfig.currentPage ? paginatorConfig.currentPage : currentPage;
      const handleSetCurrentPage = paginatorConfig.setCurrentPage ? paginatorConfig.setCurrentPage : setCurrentPage;
      const handleSetShowingAll = paginatorConfig.setShowingAll ? paginatorConfig.setShowingAll : setShowingAll;
      return <Paginator handleChange={handleSetCurrentPage}
                        perPage={paginatorConfig.perPage}
                        recordName={paginatorConfig.recordName}
                        totalItems={items.length}
                        allowShowAll={paginatorConfig.allowShowAll}
                        showingAll={showAll}
                        setShowingAll={handleSetShowingAll}
                        currentPage={currPage}/>;
    }
  };

  // Do handle any sorting and result limiting when a sort changes. This also runs once
  // when tables that aren't sortable get rendered for the first time
  useEffect(() => {
    let itemsToDisplay = items;
    if (sortable && currentSort.length > 0) {
      itemsToDisplay = orderBy(
        items,
        currentSort.map(sortOrder => item => sortIterate(item, sortOrder.sortKey)),
        currentSort.map(sortOrder => sortOrder.direction)
      );
    }

    if (paginatorConfig && !showAll) {
      const currPage = paginatorConfig.currentPage ? paginatorConfig.currentPage : currentPage;
      const rangeStart = currPage * paginatorConfig.perPage;
      const rangeEnd = rangeStart + paginatorConfig.perPage;
      setItemsToDisplay(itemsToDisplay.slice(rangeStart, rangeEnd));
    } else if (paginatorConfig && showAll) {
      setItemsToDisplay(itemsToDisplay);
    } else {
      // If a result limiter config was given use that over resultLimit
      const resultLimitToUse = resultLimiterSelection ? resultLimiterSelection : resultLimit;
      setItemsToDisplay(resultLimitToUse ? itemsToDisplay.slice(0, resultLimitToUse) : itemsToDisplay);
    }
  }, [currentSort, resultLimiterSelection, currentPage, sortable, items, resultLimit, paginatorConfig, showingAll]);

  const tableProps = {className, bordered, borderless, dark, hover, responsive, striped, size};

  return (
    <>
      <Table {...tableProps}>
        {renderHeaders()}
        {renderBody()}
        {renderTableFooter()}
      </Table>
      {renderPaginator()}
      {renderResultsLimiter()}
    </>
  );
};

export default React.memo(CustomTable);