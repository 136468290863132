import React, {useCallback, useEffect, useState} from 'react';

import NotificationList from './NotificationList';
import {SsoNotification} from '../types';
import {Duration, DurationType} from '../util';

type Props = {
  ssoApi: {
    notifications: () => Promise<SsoNotification[]>
    acknowledgeNotification: (id: number) => Promise<SsoNotification>
  };
}

const SsoNotificationList = ({ssoApi}: Props) => {
  const [notifications, setNotifications] = useState<SsoNotification[]>([]);

  const getNotifications = useCallback(
    async () => {
      try {
        const notifications = await ssoApi.notifications();
        setNotifications(notifications);
      } catch (error) {
        // the interval will try again, so silently fail
        setNotifications([]);
      }
    },
    [ssoApi]
  );

  const acknowledgeNotification = useCallback(
    async (notificationToAcknowledge: SsoNotification) => {
      // Remove from the list immediately and then do an async request.
      setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== notificationToAcknowledge.id));
      try {
        await ssoApi.acknowledgeNotification(notificationToAcknowledge.id);
      } catch (error) {
        // Do nothing if the request fails because the interval will
        // refresh the list again. The user will have to delete it again.
        // The hope is that this will rarely happen, and if so will be raised as a bug.
      }
    },
    [ssoApi]
  );

  useEffect(() => {
      getNotifications().then();
      // poll every 10 minutes
      const interval = setInterval(getNotifications, Duration.of(10, DurationType.MINUTES));
      // clear the interval out when input change
      return () => {
        clearInterval(interval);
      };
    },
    [getNotifications]
  );

  return (
    <NotificationList notifications={notifications}
                      acknowledgeHandler={acknowledgeNotification}/>
  );
};

export default SsoNotificationList;