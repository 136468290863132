import React from 'react';
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {SsoNotification} from '../types';

type Props = {
  notifications: SsoNotification[]
  acknowledgeHandler: (notification: SsoNotification) => void
}

const NotificationList = ({
                            notifications,
                            acknowledgeHandler
                          }: Props) => {

  const deleteNotification = (e: React.MouseEvent<SVGSVGElement, MouseEvent>, notification: SsoNotification) => {
    e.stopPropagation();
    acknowledgeHandler(notification);
  };

  if (notifications.length === 0) {
    return null;
  } else {
    return (
      <UncontrolledDropdown nav inNavbar className="NotificationList">
        <DropdownToggle nav title="Notification List" aria-label="notification list" className="btn btn-link">
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="bell"/>
            <span className="fa-layers-counter notification-badge">{notifications.length}</span>
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          {notifications.map((notification) => {
            return (
              <DropdownItem tag="div" key={notification.id} className="d-flex justify-content-between align-items-center">
                <div>
                  <div>{notification.text}</div>
                  <div className="text-muted small">{notification.timeAgo}</div>
                </div>
                <Button color="link" className="text-danger">
                  <FontAwesomeIcon icon="trash" onClick={(e) => deleteNotification(e, notification)}/>
                </Button>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
};

export default NotificationList;
