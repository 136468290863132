import React, {useState} from 'react';
import {FormFeedback, FormGroup, Input, InputGroup, Label} from 'reactstrap';
import {FieldHelperProps, useField, useFormikContext} from 'formik';

import {ButtonIcon} from '../components';
import {BootstrapFormControlSize} from '../types';

type Props = {
  [key: string]: any
  id?: string
  name: string
  bsSize?: BootstrapFormControlSize
  formGroupClass?: string
  labelText?: string
  disableFloatingLabel?: boolean
  ariaLabel?: string
  disabled?: boolean
  disableOnSubmit?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
  noFormikOnChange?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLDivElement>, fieldActions: FieldHelperProps<any>) => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>, fieldActions: FieldHelperProps<any>) => void
}

const FormikPasswordInput = ({
                               id,
                               name,
                               bsSize,
                               formGroupClass,
                               labelText,
                               disableFloatingLabel = false,
                               ariaLabel,
                               disabled,
                               disableOnSubmit = true,
                               onBlur,
                               onKeyUp,
                               onChange,
                               noFormikOnChange,
                               ...otherProps
                             }: Props) => {
  const [field, meta, helpers] = useField(name);
  const {isSubmitting, validateOnMount} = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);
  const [focused, setFocused] = useState(false);
  const isInvalid = validateOnMount ? !!meta.error : !!(meta.error && meta.touched);
  const idToUse = id ? id : `${name}Input`;
  const type = showPassword ? 'text' : 'password';
  const buttonLabelText = showPassword ? 'Hide Password' : 'Show Password';

  // Needed in order to handle floating and static label CSS
  let derivedGroupClass = formGroupClass ? formGroupClass : '';
  if (!field.value && !focused && !disableFloatingLabel) {
    derivedGroupClass += ' has-placeholder';
    if (bsSize) {
      derivedGroupClass += ` ${bsSize}`;
    }
  }

  const renderLabel = () => {
    if (labelText) {
      let labelClass = 'label-static';
      // File inputs and textarea should always be static, and for
      // all other inputs a label should be static if has focus and no value.
      // Otherwise display the label as a placeholder.
      if (!disableFloatingLabel && !focused && (field.value === '' || field.value === undefined || field.value === null)) {
        labelClass = 'label-placeholder';
      }
      labelClass = labelClass === 'label-static' && focused ? `${labelClass} focused` : labelClass;
      labelClass = isInvalid ? `${labelClass} is-invalid` : labelClass;

      return (
        <Label htmlFor={idToUse}
               className={labelClass}
               size={bsSize}>
          {labelText}
        </Label>
      );
    }

    return null;
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => {
    // Don't perform Formik's default on change if noFormikOnChange is true
    if (!noFormikOnChange) {
      await field.onChange(e);
    }

    // Call additional on change function if provided
    if (onChange) {
      onChange(e, helpers);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>, helpers: FieldHelperProps<any>) => {
    if (onBlur) {
      onBlur(e, helpers);
    }
    setFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, helpers: FieldHelperProps<any>) => {
    if (onKeyUp) {
      onKeyUp(e, helpers);
    }
  };

  return (
    <FormGroup onFocus={() => setFocused(true)}
               onBlur={(e: React.FocusEvent<HTMLDivElement>) => handleBlur(e, helpers)}
               className={derivedGroupClass}>
      {renderLabel()}
      <InputGroup>
        <Input {...field}
               {...otherProps}
               id={idToUse}
               type={type}
               value={field.value}
               aria-label={ariaLabel ? ariaLabel : labelText}
               onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, helpers)}
               onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(e, helpers)}
               bsSize={bsSize}
               disabled={disabled || (isSubmitting && disableOnSubmit)}
               invalid={isInvalid}/>
        <ButtonIcon ariaLabel={buttonLabelText}
                    title={buttonLabelText}
                    className="ml-2"
                    icon={showPassword ? 'eye-slash' : 'eye'}
                    onClick={() => setShowPassword(!showPassword)}/>
      </InputGroup>
      <FormFeedback>{meta.error}</FormFeedback>
    </FormGroup>
  );
};

export default FormikPasswordInput;
