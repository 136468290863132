import Cookies from 'js-cookie';

import RestApiHelper from './RestApiHelper';
import SsoSpringConfig from './SsoSpringConfig';
import {SsoNotification, User} from '../types';
import {Duration, DurationType} from '../util';

export class SsoSpringRestApi {
  private _ssoSpringConfig: SsoSpringConfig;
  private readonly _restApiHelper: RestApiHelper;
  private readonly _removeSessionData = () => {
    // Remove the lastActivity cookie and JWT in local storage
    Cookies.remove(
      this._ssoSpringConfig.lastActivityCookieName,
      {
        domain: this._ssoSpringConfig.lastActivityCookieDomain
      }
    );
    localStorage.removeItem(this._ssoSpringConfig.jwtKey);
  };

  constructor(ssoSpringConfig: SsoSpringConfig) {
    this._ssoSpringConfig = ssoSpringConfig;

    this._restApiHelper = new RestApiHelper({
      path: ssoSpringConfig.apiUrl,
      jwtKey: ssoSpringConfig.jwtKey,
      lastActivityCookieName: ssoSpringConfig.lastActivityCookieName,
      lastActivityCookieDomain: ssoSpringConfig.lastActivityCookieDomain,
      // Redirect to login by default for any unauthorized requests
      unauthorizedHandler: this.redirectToLogin,
      defaultFetchConfig: {
        credentials: 'include' // Included credentials by default, so needed cookies on base domain are included
      }
    });
  }

  get restApiHelper() {
    return this._restApiHelper;
  }

  // The method to log the user in by username and password
  login = async (username: string, password: string): Promise<string> => {
    const jwt = await this._restApiHelper.postWithTextResponse(
      'client-auth/login',
      {
        headers: {
          'Authorization': 'Basic ' + btoa(username + ':' + password)
        }
      }, {
        // The caller is responsible for handling any unauthorized failures in this case.
        // In this case the only caller is the SSO Login component.
        suppressUnauthorizedHandler: true
      }
    );
    localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
    return jwt;
  };

  // Used to attempt a login from a refresh token. This is intended to only called once when an application loads.
  loginFromRefreshToken = async () => {
    const jwt = await this._restApiHelper.postWithTextResponse(
      'client-auth/jwt',
      {},
      {
        // Suppress the the unauthorized handler for this call
        suppressUnauthorizedHandler: true,
        // Don't count this request as part of user activity since it runs in the background
        ignoreLastActivity: true
      }
    );
    localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
  };

  // Used to get all the user's details which will be used to assist in rendering decisions
  currentUser = async (): Promise<User> => {
    return await this._restApiHelper.getWithJsonResponse('users/current-user');
  };

  // This is intended to be used when user clicks sign out button. It will not set a redirect URL.
  signOut = async (): Promise<void> => {
    try {
      await this._restApiHelper.deleteWithEmptyResponse('client-auth/jwt');
    } finally {
      this._removeSessionData();
      window.location.href = `${this._ssoSpringConfig.webUrl}/login?signOut=true`;
    }
  };

  // This is the same as the signOut method except a redirect URL parameter will be set in the login URL.
  // If the user signs in again, SS0 will redirect to this URL. This is intended to be used when a user
  // goes to the URL of an application, isn't authorized for whatever reason, but should be redirected
  // back to that URL after they successfully sign in.
  redirectToLogin = async () => {
    try {
      // If token was already deleted, not need to call API to delete server side cookies
      if (localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
        await this._restApiHelper.deleteWithEmptyResponse('client-auth/jwt');
      }
    } finally {
      this._removeSessionData();
      if (!window.location.href.startsWith(`${this._ssoSpringConfig.webUrl}/login`)) {
        const currentLocation = window.location.href;
        const newUrl = new URL('login', this._ssoSpringConfig.webUrl);
        newUrl.search = new URLSearchParams({signOut: 'true', redirect: currentLocation}).toString();
        window.location.href = newUrl.toString();
      }
    }
  };

  // Used to retrieve notifications. This should not need to be called directly if using the
  // SsoNotificationList component
  notifications = async (): Promise<SsoNotification[]> => {
    // Avoid calls that will fail if local storage for cleared from a logout
    if (!localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
      return [];
    } else {
      return await this._restApiHelper.getWithJsonResponse('notifications',
        {},
        {
          // This is called in the background, so don't count this as active user activity
          ignoreLastActivity: true,
          // The caller is responsible to handling any unauthorized failures in this case
          suppressUnauthorizedHandler: true
        }
      );
    }
  };

  // Used to acknowledge a notification. This should not need to be called directly if using the
  // SsoNotificationList component
  acknowledgeNotification = async (notificationId: number): Promise<SsoNotification> => {
    return await this._restApiHelper.putWithJsonResponse(`notifications/${notificationId}/acknowledge`,
      {},
      {
        // The caller is responsible to handling any unauthorized failures in this case
        suppressUnauthorizedHandler: true
      }
    );
  };

  // Return an interval that checks and maintains the user's session based on last activity
  monitorSession = (sessionWarningHandler: () => void) => {
    const checkSession = async () => {
      const isOnFaqPage = window.location.pathname === '/faq';
      // If a JWT is present we can assume the user is signed in and we need to check how long they have been active
      if (localStorage.getItem(this._ssoSpringConfig.jwtKey)) {
        const lastActivityCookieValue = Cookies.get(this._ssoSpringConfig.lastActivityCookieName);
        const lastActivity = typeof lastActivityCookieValue === 'string' ? new Date(lastActivityCookieValue) : new Date();
        const timeElapsed = new Date().getTime() - lastActivity.getTime();
        const timeLeft = this._ssoSpringConfig.maxSession - timeElapsed;
        if (timeLeft <= 0 && !isOnFaqPage) {
          await this.redirectToLogin();
        } else if (timeLeft <= this._ssoSpringConfig.sessionWarningAt) {
          // Renew the token if it is close to expiration (2 minutes left). This does not prevent
          // the user from getting signed out if they are inactive for too long. That is what the check
          // before this one handles.
          try {
            const jwt = await this._restApiHelper.postWithTextResponse(
              'client-auth/jwt',
              {},
              {
                // Suppress the the unauthorized handler for this call
                suppressUnauthorizedHandler: true,
                // don't count this request as part of user activity since it runs in the background
                ignoreLastActivity: true
              }
            );
            localStorage.setItem(this._ssoSpringConfig.jwtKey, jwt);
          } catch (e) {
            // If a network happened failed gracefully, otherwise something is wrong with the refresh token, so logout out
            if (e instanceof TypeError) {
              console.debug('Refresh token could not be retrieved. Failing silently in hopes this was just a network hiccup.', e);
            } else {
              // A non-network error happened when trying to get a new refresh token, so signing out.
              await this.redirectToLogin();
            }
          }
          if (sessionWarningHandler) {
            sessionWarningHandler();
          }
        }
      } else {
        // If the user doesn't have a JWT and they are not on the login screen then redirect them. This likely means,
        // another tab logged them out.
        if (!isOnFaqPage && !window.location.href.startsWith(`${this._ssoSpringConfig.webUrl}/login`)) {
          await this.redirectToLogin();
        }
      }
    };

    return setInterval(async () => await checkSession(), Duration.of(20, DurationType.SECONDS));
  };

  sessionDataExists = (): boolean => {
    return !!Cookies.get(this._ssoSpringConfig.lastActivityCookieName) && !!localStorage.getItem(this._ssoSpringConfig.jwtKey);
  };

  sessionTimeLeftGreaterThanWarningThreshold = (): boolean => {
    if (!Cookies.get(this._ssoSpringConfig.lastActivityCookieName)) {
      return false;
    }
    const lastActivityCookieValue = Cookies.get(this._ssoSpringConfig.lastActivityCookieName);
    const lastActivity = typeof lastActivityCookieValue === 'string' ? new Date(lastActivityCookieValue) : new Date();
    const timeElapsed = new Date().getTime() - lastActivity.getTime();
    const timeLeft = this._ssoSpringConfig.maxSession - timeElapsed;
    return timeLeft >= this._ssoSpringConfig.sessionWarningAt;
  };

  bumpLastActivity = (): void => {
    Cookies.set(this._ssoSpringConfig.lastActivityCookieName,
      new Date().toISOString(),
      {
        domain: this._ssoSpringConfig.lastActivityCookieDomain,
        secure: window.location.protocol.startsWith('https'),
        expires: 1 // expires in 1 day. Also gets removed when an explicit sign out or timeout happens
      }
    );
  };
}

export default SsoSpringRestApi;